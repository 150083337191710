
<template>
	<div class="rating">
		<IconStar 
			class="rating__ico" 
			:color="colorStar(index)" 
			v-for="index in count" 
			:key="index"
			@click="saveRating(index)"
		/>
	</div>
</template>

<script setup>
  	import IconStar from '@/components/icons/IconStar.vue';
</script>

<script>
	export default {
	    name: 'StarsRating',
	    props: ['modelValue', 'count'],
	    data: () => ({
	    }),

	    computed: {
	    	colorStar () {
	    		return (index) => {
	    			if(index <= this.modelValue) {
	    				return '#008DD2';
	    			} else {
	    				return '#D9E4EB';
	    			}
	    		}
	    	},
	    },

	    methods: {
	    	saveRating (index) {
	    		this.$emit('update:modelValue', index);
	    	},
	    },
    }
</script>

<style scoped>
	.rating {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 15px;
	}
	.rating__ico {
		cursor: pointer;
	}
</style>