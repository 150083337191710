<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9 12V2.5C9 1.67157 9.67157 1 10.5 1V1C11.3284 1 12 1.67157 12 2.5V12" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M4 16V15C4 13.3431 5.34315 12 7 12H14C15.6569 12 17 13.3431 17 15V16" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M4 16C4 19 2 21 2 21H15C15 21 17 19 17 16" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<g opacity="0.35">
	<path d="M4 16H17" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M7 16C7 16.9791 6.78699 17.8516 6.5 18.5829" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M10 16C10 16.9791 9.78699 17.8516 9.5 18.5829" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M13 16C13 16.9791 12.787 17.8516 12.5 18.5829" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</g>
	<circle opacity="0.35" cx="19" cy="20" r="1" fill="#AEC1CC"/>
	<circle opacity="0.35" cx="18" cy="10" r="1" fill="#AEC1CC"/>
	<circle cx="21" cy="15" r="1" fill="#AEC1CC"/>
	<path d="M19 1V6M21 4H17" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round"/>
	<path d="M3 5V9M5 7H1" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round"/>
	</svg>
</template>
