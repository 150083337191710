<template>
  <div class="file">
    <div class="file__label">{{ label }}</div>
    <div class="file__container" :class="{'empty': modelValue.length == 0}">
      <div class="file__item" v-for="(file, index) in modelValue"  :key="'file-' + index">
        <IconFile class="file__ico" />
        <div class="file__info">
          <div class="file__name">
            {{ file.title }}
          </div>
          <div class="file__bottom">
            <!-- <a class="file__link" :href="file.link"> Скачать </a> -->
            <div class="file__hint"> {{ file.ext }} — {{ helpers.formatBytes(file.size) }} </div>
          </div>
        </div>
        <a class="file__delete" @click="deleteFile(index)" v-if="!viewMode"></a>
      </div>
    </div>
    <div class="file__button" v-if="!viewMode">
      <input type="file" :id="uniq" @change="onFileChanged($event)" multiple="true" :accept="accept" ref="input">
      <label :for="uniq" class="btn btn--outline btn--md btn--inline">
        <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
        <div class="btn--text">Загрузить файл</div>
      </label>
    </div>
  </div>
</template>

<script setup>
  import IconFile from '@/components/icons/svg/IconFile.vue';
</script>

<script>
	export default {
	    name: 'FilePicker',
	    props: ['modelValue', 'uniq', 'label', 'viewMode'],
	    data: () => ({
        accept: '.doc,.docx,.xls,.xlsx,.odt,.ods,.pdf,.rtf,.zip,.dwg,.dfx,.dxf,.txt',
	    }),
	    methods: {
	    	deleteFile(idx) {
		      let files = this.modelValue;
		      files.splice(idx, 1);
	    		this.$emit('update:modelValue', files);
          this.$refs.input.value = null;
	    	},
	    	async onFileChanged (e) {
	        const input = e.currentTarget;
	        if(input.files.length > 0) {
	        	let files = this.modelValue;
          	Array.from(input.files).forEach(async file => {
              console.log(file);
          		let base64 = await this.helpers.toBase64(file);
          		files.push({
                title: file.name, 
                size: file.size, 
                ext: file.type, 
                link: base64 
              });
  				    this.$emit('update:modelValue', files);
          	});
	        }
		    },
	    },
    }
</script>

<style scoped>

.file {
  background-color: var(--cozh-white);
  padding: 18px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all .2s ease-out;
  position: relative;
}
.file__label {
  color: rgba(11, 40, 54, .35);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
}
.file__container {
  border-radius: 8px;
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 10px;
}
.file__container::-webkit-scrollbar {
  display: none;
}
  
.file__container.empty {
  background-color: rgba(233, 241, 244, 0.5);
  height: 90px;
  padding: 0px;
  margin-bottom: 15px;
}
.file__container.empty::before {
  content: url('@/assets/img/8.svg');
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 38px;
}
.file__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  position: relative;
}
.file__item:not(:last-child){
    margin-bottom: 10px;
}
.file__button {
}
.file__button input {
   opacity: 0;
   position: absolute;
   z-index: -1;
}


.file__delete {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 24px;
  width: 24px;
  background-color: rgba(var(--cozh-black-code), .15);
  z-index: 1;
  border-radius: 50%;
  backdrop-filter: blur(18px);
}
.file__delete::before {
  content:  url('../../assets/img/3.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.file__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}
.file__name {
  font-size: 13px;
  font-weight: 500;
  word-break: break-all;
}
.file__bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.file__link {
    font-style: normal;
    color: var(--cozh-blue);
    position: relative;
    white-space: nowrap;
    margin-right: 10px;
    cursor: pointer;
    line-height: 1;
}
.file__hint, .file__link {
    font-size: 11px;
    font-weight: 500;
}
.file__hint {
    color: rgba(var(--cozh-black-code), .5);
}
.file__ico {
  min-width: 22px;
}
</style>