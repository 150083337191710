<template>
  <MessageDialogue ref="messageDialogue" />
  <div class="wrap">

    <div class="load-container" :class="{'load': isLoad}"></div>

    <template v-if="data !== null && !isLoad">
      
      <div class="cleaning__img" :style="{backgroundImage: 'url(' + data.imagePath + ')' }"></div>
      
      <div class="cleaning__content">

        <div class="cleaning__block cleaning__block--address">
          <div class="cleaning__label">
            Адрес
          </div>
          <div class="cleaning__adderess">{{data.address}}</div>
        </div>

        <div class="cleaning__block cleaning__block--company">
          <IconCleaning />
          <div class="cleaning__company">{{data.company}}</div>
        </div>

        <div class="cleaning__block cleaning__block--section">
          <div class="cleaning__label">
            Секция / подъезд
          </div>
          <div class="cleaning__section">{{data.sectionName}}</div>
        </div>

        <collapse-container
          v-for="(floor, index) in data.floors"
          :key="index" 
          ref="module" 
          :isOpenCreate="true"
        >
          <template v-slot:header>
            {{ floor.label }}
          </template>
          <template v-slot:body>

            <div class="cleaning__item">
              <div class="cleaning__subtitle"> Последняя уборка проводилась </div>
              <div class="cleaning__badge cleaning__badge--blue">{{ moment(floor.lastDate).format('DD MMMM YYYY') }}</div>
            </div>
            <div class="cleaning__item">
              <div class="cleaning__subtitle"> Следующая уборка запланирована </div>
              <div class="cleaning__badge cleaning__badge--border">{{ moment(floor.nextDate).format('DD MMMM YYYY') }}</div>
            </div>
          </template>
        </collapse-container>

        <div class="feedback" v-if="!isSend">
          <div class="feedback__header">
            Оцените текущее санитарное состояние помещения
          </div>
          
          <div class="feedback__stars">
            <StarsRating v-model="rating" :count="5" />
          </div>

          <div class="feedback__body">
            <div class="form-group">
              <div class="form-control form-control--border">
                <textarea v-model="comment" rows="3"></textarea>
                <label> Ваш комментарий </label>
              </div>
            </div>
            <a class="btn btn--default btn--lg btn--inline" @click="saveFeedback($event)"> 
              <img class="btn--ico btn--ico--left" src="@/assets/img/2.svg">
              <div class="btn--text"> Отправить оценку </div>
              <div class="btn--loader"></div>
            </a>
          </div>
        </div>


      </div>

    </template>

  </div>
</template>

<script setup>
  import IconCleaning from '@/components/icons/svg/IconCleaning.vue';
  import CollapseContainer from '@/components/ux/CollapseContainer.vue';
  import StarsRating from '@/components/ux/StarsRating.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
</script>

<script>
  export default {
    name: 'PageReportsCleaning',
    data: () => ({
      isLoad: true,
      isSend: false,
      data: null,

      comment: null,
      rating: 0,
    }),

    computed: {
      location_id () {
        return this.$route.params.location_id;
      },
    },

    created () {
      Promise.all([this.loadData()])
        .then(() => {
          this.beforeRender();
        })
        .catch(()=> {
        })
        .finally(() => {
          this.isLoad = false;
        })
    },

    methods: {
      beforeRender () {
      },

      async saveFeedback (e) {
        
        const btn = e.currentTarget;

        if(this.rating == 0) {
          this.$refs.messageDialogue.show({ 
            message: 'Для оценки функционала выберите от 1 до 5 звёзд', 
            okButton: 'Подтвердить'
          });
          return;
        }

        btn.classList.add('btn--load');

        await this.axios.post(this.$store.state.api_endpoint + '/feedback/', {assessment: this.rating, comment: this.comment, sectionId: this.location_id });

        this.isSend = true;

        this.$refs.messageDialogue.show({ 
            message: 'Спасибо за обратную связь', 
            okButton: 'Подтвердить'
        });


        btn.classList.remove('btn--load');

      },
      async loadData () {

        const {data} = await this.axios.get(this.$store.state.api_endpoint + '/tracker/' + this.location_id + '/dates?locationId=' + this.location_id);
        
        this.data = data;

      },

    },
  }
</script>

<style scoped>
  .wrap {
    overflow: visible;
  }

  .feedback {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    width: 100%;
  }
  .feedback__header {
    padding: 15px 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.15;
  }
  .feedback__stars {
    border-top: 1px solid rgba(233, 241, 244, 1);
    border-bottom: 1px solid rgba(233, 241, 244, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 72px;
  }
  .feedback__body {
    padding: 15px 18px;
  }
  


  .cleaning__img {
    width: calc(100% + 30px);
    display: flex;
    aspect-ratio: 375 / 240;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -15px;
    margin-top: -15px;
  }

  .cleaning__content {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .cleaning__block {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 15px 18px;
    width: 100%;
  }
  .cleaning__block--company {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .cleaning__block--section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .cleaning__block--address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
  }




  .cleaning__label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: rgba(var(--cozh-black-code), .35);
  }
  .cleaning__adderess {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
  }
  .cleaning__company {
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .cleaning__section {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }
  .cleaning__subtitle {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.05em;
    color: #AEC1CC;
    text-transform: uppercase;
    font-weight: 600;
  }
  .cleaning__item {
    padding: 15px 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .cleaning__item:not(:first-child) {
    border-top: 1px solid rgba(233, 241, 244, 1);
  }


  .cleaning__badge {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    padding: 7px 13px;
    border-radius: 20px;
    width: fit-content;
  }
  .cleaning__badge--blue {
    color: #fff;
    background-color: var(--cozh-blue);
  }
  .cleaning__badge--border {
    color: var(--cozh-black);
    background-color: transparent;
    border: 1px solid rgba(217, 228, 235, 1);
  }

</style>