<template>
	<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.1382 1.57564C15.012 -0.525214 17.988 -0.525215 18.8618 1.57564L21.5383 8.0106C21.9067 8.89627 22.7396 9.50141 23.6957 9.57807L30.6428 10.135C32.9109 10.3168 33.8305 13.1473 32.1025 14.6275L26.8096 19.1615C26.0811 19.7855 25.7629 20.7646 25.9855 21.6977L27.6026 28.4769C28.1305 30.6901 25.7228 32.4394 23.781 31.2534L17.8334 27.6206C17.0148 27.1206 15.9852 27.1206 15.1666 27.6206L9.21897 31.2534C7.2772 32.4394 4.86948 30.6901 5.39742 28.4769L7.0145 21.6977C7.23706 20.7646 6.91892 19.7855 6.19044 19.1615L0.897494 14.6275C-0.830524 13.1473 0.0891406 10.3168 2.35719 10.135L9.30427 9.57807C10.2604 9.50141 11.0933 8.89627 11.4617 8.0106L14.1382 1.57564Z" :fill="color"/>
	</svg>
</template>

<script>
	export default {
	    name: 'IconStar',
	    props: ['color'],
    }
</script>
