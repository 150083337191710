<template>
	<div class="beracrumbs">
		<div class="beracrumbs__content">
			<template v-if="param !== null">
				<router-link class="beracrumbs__prev" v-if="param.prev !== null" :to="param.prev.link">
					<IconPrev color="#008DD2" />
					<span> {{ param.prev.name }} </span>
				</router-link>
				<div class="beracrumbs__current" v-if="param.current !== null "> {{ param.current.name }} </div>
			</template>
		</div>
	</div>
</template>

<script setup>
  import IconPrev from '@/components/icons/svg/IconPrev.vue';
</script>

<script>
  export default {
    name: 'BreadCrumbs',
    props: ['param'],
    data: () => ({
    }),
    methods: {
    },
  }
</script>

<style scoped>
	.beracrumbs {
		padding-bottom: 10px;
		width: 100%;
		overflow-x: auto;
	}


	.beracrumbs::-webkit-scrollbar {
    display: none;
  }
  .beracrumbs::-webkit-scrollbar-thumb {
    display: none;
  }


	.beracrumbs__content {
		height: 40px;
		border-radius: 20px;
		background-color: var(--cozh-white);
		width: fit-content;
		min-width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0px 20px;
		gap: 25px;
	}
	.beracrumbs__prev {
		font-size: 13px;
	  font-weight: 500;
	  color: var(--cozh-blue);
		display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
	}
	.beracrumbs__prev span {
		white-space: nowrap;
	  line-height: 1;
	}
	.beracrumbs__current {
		font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: var(--cozh-black);
    white-space: nowrap;
	}
</style>